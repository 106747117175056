import { HTTPService } from "./service";

// GET PRODUCT DATA
export const getProductData = async ({ productName, mainSize }) => {
  const params = {};
  if (mainSize) {
    params.size = mainSize;
  }
  params.activegs = 0;

  return await HTTPService({
    method: "GET",
    url: `/ProductTableMergeds/getProductData?productName=${productName}`,
    params: params,
  });
};

// GET OTHER PRODUCT DATA
export const getOtherProductData = async ({ productName, size }) => {
  const params = {};
  if (size) {
    params.size = size;
  }
  params.activegs = 0;

  return await HTTPService({
    method: "GET",
    url: `/ProductTableMergeds/getProductOtherData?productName=${productName}`,
    params: params,
  });
};

// SUBMIT A BUG
export const submitBug = async (data) =>
  await HTTPService({
    method: "POST",
    url: `/UserBugReports`,
    data: data,
  });

//PDP FAQ
export const pdpFaq = async (productCode) =>
  await HTTPService({
    url: `/product_faqs/?filter={"where": {"productCode":"${productCode}"}}`,
    method: "GET",
  });

//CHECK BRAND//
export const brandClaimStatus = async (brand) =>
  await HTTPService({
    url: `/product_brands?filter[where][brand_name]=${brand}`,
    method: "GET",
  });


//SIMILAR PRODUCTS
export const similarProducts = async ({ productNameURL, clientParam, userLocation, filtersParam }) => {
  const queryParams = {
    productName: productNameURL,
    limit: 20,
    client: clientParam,
    ...filtersParam
  };

  if (userLocation !== undefined) {
    queryParams.location = userLocation;
  }

  try {
    const response = await HTTPService({
      method: "GET",
      url: "/ProductTableMergeds/getRelatedProducts",
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.error("Error fetching related products:", error);
    throw error;
  }
};

// GET BETTER-FOR-YOU OPTIONS
export const getBFY = async ({ upcCode, clientParam, userLocation, filtersParam }) => {
  const queryParams = {
    upcCode: upcCode,
    client: clientParam,
    location: userLocation,
    limit: 20,
    ...filtersParam
  };

  try {
    const response = await HTTPService({
      method: "GET",
      url: `/ProductTableMergeds/getBetterForYouProductsUPC`,
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.error("Error fetching related products:", error);
    throw error;
  }
}