import React, { useEffect } from 'react';
import AccordionFilter from '../../Components/AccordionFilter';
import { filterData } from '../../Utils/Constants';
import { useFilters } from '../../Context/FiltersContext';
import { useNavigate } from 'react-router-dom';
import { handleSelectedFilters } from '../../Gtag'
import './index.scss';

const Filters = () => {
    const { loadFilters, selectedFilters, getFilterLabelById, clearFilters } = useFilters()
    const navigate = useNavigate()

    useEffect(() => {
        loadFilters();
    }, [])

    const handleMove = () => {
        getFiltertoAnalytics()
        setTimeout(() => {
            navigate(-1);
        }, 250);
    }

    const getFiltertoAnalytics = () => {
        let filterArr = []
        Object.keys(selectedFilters).forEach(item => {
            let filter = getFilterLabelById(
                item.split("_")[0],
                parseInt(item.split("_")[1])
            )
            filterArr.push(filter)
        })
        filterArr.map(item => handleSelectedFilters(item))
    }

    const handleClear = async () => {
        await clearFilters();
        handleMove()
    }

    return (
        <div className='filter-wrap py-4'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-6">
                        <div className="pb-3 d-flex align-items-center justify-content-between">
                            <h2 className="heading">
                                Filter Your Search
                            </h2>
                        </div>

                        <div className="filter-accordion-wrap mt-1">
                            <div className="accordion accordion-filter" id="accordionFilter">
                                <AccordionFilter
                                    filters={filterData.diets}
                                    name="Diets"
                                />
                                <AccordionFilter
                                    filters={filterData.allergies}
                                    name="Allergies"
                                />
                                <AccordionFilter
                                    filters={filterData.ingredients}
                                    name="Ingredients"
                                />
                                <AccordionFilter
                                    filters={filterData.nutrients}
                                    name="Nutrients"
                                />
                                <AccordionFilter
                                    filters={filterData.sustainability}
                                    name="Sustainability"
                                />
                                <AccordionFilter
                                    filters={filterData.values}
                                    name="Values"
                                />
                            </div>
                        </div>

                        <div className='d-flex align-items-center justify-content-center my-2 filter-btns'>
                            <button onClick={handleMove} className='btn btn-reset m-2 apply-btn'> Apply Filters </button>
                            <button onClick={handleClear} className='btn btn-reset m-2 bg-white'>Reset Filters</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filters